<template>
  <div class="contact">
    <banner title="Sign Up" />
    <div class="text-center text-h4 mt-8">Sign up for our Newsletter</div>
        <iframe src="https://keap.app/contact-us/1842863144950258" frameborder="0"
        width="100%" height="800"></iframe>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
export default {
  name: "Contact",
  data: () => ({}),
  components: { Banner },
};
</script>
